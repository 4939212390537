@font-face {
  font-family: "Scto Grotesk";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Scto-Grotesk-A-Regular.eot");
  src: url("./assets/fonts/Scto-Grotesk-A-Regular.woff") format("woff"),
    url("./assets/fonts/Scto-Grotesk-A-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Scto Grotesk Light";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Scto-Grotesk-A-Light.eot");
  src: url("./assets/fonts/Scto-Grotesk-A-Light.woff") format("woff"),
    url("./assets/fonts/Scto-Grotesk-A-Light.otf") format("opentype");
}

@font-face {
  font-family: "Scto Grotesk Medium";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Scto-Grotesk-A-Medium.eot");
  src: url("./assets/fonts/Scto-Grotesk-A-Medium.woff") format("woff"),
    url("./assets/fonts/Scto-Grotesk-A-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Scto Grotesk Bold";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Scto-Grotesk-A-Bold.eot");
  src: url("./assets/fonts/Scto-Grotesk-A-Bold.woff") format("woff"),
    url("./assets/fonts/Scto-Grotesk-A-Bold.otf") format("opentype");
}
